export const ADMIN_LOGIN = "/login";
export const LOGOUT = "/logout";
export const LIST_ALL_CUSTOMERS = "/getAllCustomer";
export const LIST_ALL_RETAILERS = "/getAllRetailer";
export const LIST_ALL_PRODUCTS = "/getAllProducts";
export const GET_ADMIN_DASHBOARD_DATA = "/adminDashboardData";
export const GET_USER = "/users";
export const GET_USER_REVIEW_LIST_URL = "/getUserReviewList";
export const ACTIVE_DEACTIVE_USER = "/activeDeactiveUser";
export const DELETE_USER_URL = "/deleteUser";
export const LIST_RETAILER = "/getAllRetailer";
export const LIST_PRODUCT = "/getAllProducts";
export const DELETE_PRODUCT = "/deleteProduct";
export const LIST_CATEGORY = "/getCategory";
// export const SAVE_CATEGORY = "/saveProductCategory";
export const LIST_ORDER = "/getAllOrders";
export const CHANGE_ORDER_TRACKING_STATUS = "/changeOrderTrackingStatus";
export const LIST_ALL_CATEGORY_FOR_SHOP = "getCategoryForShop";
export const APPROVE_OR_REJECT_RETAILER = "approveOrRejectRetailer";
export const APPROVE_OR_REJECT_ORDER = "approveOrRejectOrder";
export const SAVE_CATEGORY = "/saveShopCategory";
export const GET_REFERRAL_LIST = "/getReferral";
