import { createSlice } from "@reduxjs/toolkit";
import { referralList } from "./referralThunk";

const initialState = () => ({
  referralData: [],
  referralLoading: false,
});

const referralSlice = createSlice({
  initialState: initialState(),
  name: "referral",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(referralList.pending, (state) => {
        state.referralLoading = true;
      })
      .addCase(referralList.fulfilled, (state, { payload }) => {
        state.referralLoading = false;
        state.orderData = payload?.data?.rows;
      })
      .addCase(referralList.rejected, (state) => {
        state.referralLoading = false;
      });
  },
});

export const {} = referralSlice.actions;
export default referralSlice.reducer;
