import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";
import { useDispatch } from "react-redux";
import ChangePasswordModal from "../components/Modal/ChangePasswordModel";
import { logout } from "../module/login/features/authThunk";

const Header = ({ isOpen, setIsopen }) => {
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-md header-top-section">
        <div className="container-fluid p-2">
          <div className="form-inline ml-auto d-flex">
            <div className="header-toggle pointer" onClick={ToggleSidebar}>
              <BiMenuAltLeft className="menu-icon" />
            </div>
          </div>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              className="d-flex justify-content-center align-items-center"
              variant="success"
              id="dropdown-split-basic"
            >
              <AiOutlineUser style={{ color: "#fff" }} size={20} />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleModalOpen()}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Item onClick={() => dispatch(logout())}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isModalOpen && (
          <ChangePasswordModal
            isOpen={isModalOpen}
            data={isOpen.data}
            onClose={() => setIsModalOpen(false)}
            // onSubmit={onSubmit}
          />
        )}
      </nav>
    </>
  );
};

export default Header;
