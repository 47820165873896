import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDROPPAdmin } from "../../../services/api";
import {
  ACTIVE_DEACTIVE_USER,
  GET_USER,
  GET_USER_REVIEW_LIST_URL,
  LIST_ALL_CUSTOMERS,
} from "../../../constants/url_helpers";

export const usersList = createAsyncThunk(
  "users/usersList",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.post(LIST_ALL_CUSTOMERS, payload);
      let json = await res.data;

      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const userProfile = createAsyncThunk(
  "users/userProfile",
  async (payload, thunk) => {
    try {
      let response = {};
      const userRes = await axiosDROPPAdmin.get(
        GET_USER + "/" + payload.uuid,
        payload
      );
      const userData = await userRes.data?.data;
      response = { ...response, ...userData, uuid: payload.uuid };
      const reviewRes = await axiosDROPPAdmin.post(GET_USER_REVIEW_LIST_URL, {
        id: payload.uuid,
        limit: 5,
        page: 1,
      });
      const reviews = await reviewRes.data?.data;
      const reviews_count = reviews?.count;
      const ratings = reviews?.avg_ratings;

      response.reviews = {
        rows: reviews?.rows,
        page: 1,
        limit: 5,
        count: reviews_count,
        ratings,
      };
      return { ...response };
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const activeDeactiveUser = createAsyncThunk(
  "users/activeDeactiveUser",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.get(
        ACTIVE_DEACTIVE_USER + "/" + payload,
        payload
      );
      let json = await res.data;

      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);
