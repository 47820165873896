import { Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Routes as ReactRouterRoutes,
  Route,
  useLocation,
} from "react-router-dom";
import PageLoader from "../components/Loader";
import RedirectToHome from "../components/RedirectToHome";
import RedirectToLogin from "../components/RedirectToLogin";
import Layout from "../layout";
import routesConfig from "./config";
import Home from "../components/Home";

const Public = (route) => {
  // Logic for public routes
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const Common = (route) => {
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const Private = (route) => {
  // Logic for Private routes
  const { Component } = route;

  return (
    <Suspense fallback={<PageLoader />}>
      <Component />
    </Suspense>
  );
};

const createNestedRoutes = (routes, RouteType, user) => {
  return routes.map((route, i) => {
    if (!route.Component) {
      throw new Error("Component must be required....");
    }
    if (route.children) {
      return (
        <Route path={route.path} key={i} element={<RouteType {...route} />}>
          {createNestedRoutes(route.children, RouteType, user)}
        </Route>
      );
    } else {
      if (
        route?.allowed &&
        route?.allowed?.length !== 0 &&
        !route?.allowed?.includes(user?.role)
      )
        return null;
      else {
        return (
          <Route
            key={i}
            index={route.index}
            path={route.path}
            element={<RouteType {...route} />}
          />
        );
      }
    }
  });
};

const Routes = () => {
  const { pathname } = useLocation();
  // const isAuth = true
  // const user = {}
  const { isAuth, user } = useSelector((state) => state.auth);

  const { common, private: privateRoutes, public: publicRoutes } = routesConfig;
  const validPath = useMemo(() => pathname?.startsWith("/admin"), [pathname]);
  return (
    <ReactRouterRoutes>
      {isAuth ? (
        <>
          <Route index element={<RedirectToHome />} />
          <Route path="/" element={<Layout />}>
            {createNestedRoutes(privateRoutes, Private, user)}
          </Route>
          <Route path="*" element={<RedirectToHome />} />
        </>
      ) : validPath ? (
        <>
          <Route index element={<RedirectToLogin />} />
          {createNestedRoutes(publicRoutes, Public, user)}
          <Route path="/admin/login" element={<RedirectToLogin />} />
        </>
      ) : (
        <Route path="*" element={<Home />} />
      )}
      {createNestedRoutes(common, Common)}
    </ReactRouterRoutes>
  );
};

export default Routes;
