import { toast } from "react-toastify";
import { GET_ADMIN_DASHBOARD_DATA } from "../../../constants/url_helpers";
import { axiosDROPPAdmin } from "../../../services/api";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  dashboardLoading: false,
  dashboardData: {
    recentTransactionData: [],
    recentTransactionDataModified: [],
    resourcesData: [],
    subscriptionData: [],
    topEarningCoachesData: [],
    topPayingProfessionalData: [],
    transactionReceivedData: [],
    totalActiveUsersData: 0,
  },
};

const dashboardSlice = createSlice({
  initialState,
  name: "dashboard",
  reducers: {
    setDashboardLoading: (state, action) => ({
      ...state,
      dashboardLoading: action.payload,
    }),
    setDashboardData: (state, action) => ({
      ...state,
      dashboardData: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchResourcesAdminList.fulfilled, (state, action) => ({
      ...state,
      dashboardData: {
        ...state.dashboardData,
        recentTransactionData: action?.payload?.recentTransactionData || [],
        recentTransactionDataModified:
          action.payload?.recentTransactionData?.rows.map((data) => ({
            id: data.id,
            paidbyName: data?.User?.name,
            paidByEmail: data?.User?.email,
            paidFor: data?.PaymentHistory?.paid_for,
            totalAmount: data?.charge_amount,
            createdAt: data?.created_at,
            paymentStatus: data?.PaymentHistory?.status,
            totalCommision:  (data?.charge_amount * 12.5)/100
          })),
        resourcesData: action.payload?.resourcesData?.rows,
        subscriptionData: action.payload?.subscriptionData,
        topEarningCoachesData: action.payload?.topEarningCoachesData,
        topPayingProfessionalData: action.payload?.topPayingProfessionalData,
        transactionReceivedData: action.payload?.transactionReceivedData,
        totalActiveUsersData: action.payload?.totalActiveUsersData || 0,
      },
    }));
  },
});

export const fetchResourcesAdminList = createAsyncThunk(
  "resources_list",
  async (thunkAPI) => {
    try {
      const response = await axiosDROPPAdmin.get(GET_ADMIN_DASHBOARD_DATA);
      if (response) {
        return response.data.data;
      }
    } catch (err) {
      toast.error(err);
    }
  }
);

export const { setDashboardData, setDashboardLoading } = dashboardSlice.actions;

export default dashboardSlice.reducer;
