import lazy from "../utils/lazy";

export const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ "../module/login")
);

export const SignupPage = lazy(() =>
  import(/* webpackChunkName: "SignupPage" */ "../module/signup")
);

export const DashboardPage = lazy(() =>
  import(/* webpackChunkName: "DashboardPage" */ "../module/dashboard")
);

export const CustomersPage = lazy(() =>
  import(/* webpackChunkName: "CustomersPage" */ "../module/users")
);

export const RetailerPage = lazy(() =>
  import(/* webpackChunkName: "RevenuePage" */ "../module/retailer")
);

export const ProductPage = lazy(() =>
  import(/* webpackChunkName: "ProductPage" */ "../module/products")
);

export const CategoryPage = lazy(() =>
  import(/* webpackChunkName: "CategoryPage" */ "../module/category")
);

export const OrderPage = lazy(() =>
  import(/* webpackChunkName: "OrderPage" */ "../module/orders")
);

export const TermsConditionPage = lazy(() =>
  import(
    /* webpackChunkName: "TermsConditionPage" */ "../module/login/TermsCondition"
  )
);

export const PrivacyPolicyPage = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ "../module/login/PrivacyPolicy"
  )
);

export const HomePage = lazy(() =>
  import(/* webpackChunkName: "HomePage" */ "../components/Home")
);

export const ReferralPage = lazy(() =>
  import(/* webpackChunkName: "ReferralPage" */ "../module/referral")
);
