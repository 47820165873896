const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  activePage: 1,
};

const commonSlice = createSlice({
  initialState,
  name: "common",
  reducers: {
    setActivePage: (state, action) => ({
      ...state,
      activePage: action.payload,
    }),
  },
});

export const { setActivePage } = commonSlice.actions;

export default commonSlice.reducer;
