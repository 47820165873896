import { createSlice } from "@reduxjs/toolkit";
import { orderList } from "./orderThunk";

const initialState = () => ({
  orderLoading: false,
  orderData: [],
  orderCount: 0,
});

const orderSlice = createSlice({
  initialState: initialState(),
  name: "order",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(orderList.pending, (state, { payload }) => {
        state.orderLoading = true;
      })
      .addCase(orderList.fulfilled, (state, { payload }) => {
        state.orderLoading = false;
        state.orderData = payload?.data?.rows;
        state.orderCount = payload?.data?.count;
      })
      .addCase(orderList.rejected, (state, { payload }) => {
        state.orderLoading = false;
      });
  },
});

export const {} = orderSlice.actions;
export default orderSlice.reducer;
