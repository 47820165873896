import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDROPPAdmin } from "../../../services/api";
import { ADMIN_LOGIN, LOGOUT } from "../../../constants/url_helpers";
import Cookies, { cookieKeys } from "../../../services/cookies";

export const adminLogin = createAsyncThunk(
  "auth/adminLogin",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.post(ADMIN_LOGIN, payload);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunk) => {
  const token = Cookies.get(cookieKeys.TOKEN);
  try {
    const res = await axiosDROPPAdmin.post(LOGOUT, {
      token,
    });
    let json = await res.data;
    return json;
  } catch (e) {
    return thunk.rejectWithValue(e?.response?.data);
  }
});
