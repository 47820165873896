import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import sidebarLogo from "../assets/icons/DROPP.svg";
import { menuLabels } from "../constants/menu";
import { setActivePage } from "../store/commonSlice";

const Sidebar = ({ isOpen, setIsopen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { activePage } = useSelector((state) => state.common);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  useEffect(() => {
    window.innerWidth < 991 ? setIsopen(false) : setIsopen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window]);
  const activeNav = (link) => pathname === link;
  return (
    <>
      <div className="mt-3">
        <div className={`sidebar ${isOpen === true ? "active" : ""}`}>
          <div className="sd-header">
            <img src={sidebarLogo} alt="logo" className="sidebar-logo" />
          </div>
          <div className="sd-body">
            <ul>
              {menuLabels?.map((menu) => (
                <li
                  key={menu.id}
                  className={`sd-link ${activeNav(menu.link) ? "active" : ""}`}
                  onClick={() => {
                    navigate(menu.link);
                    dispatch(setActivePage(menu.id));
                  }}
                >
                  {menu.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className={`sidebar-overlay ${isOpen === true ? "active" : ""}`}
          onClick={ToggleSidebar}
        ></div>
      </div>
    </>
  );
};

export default Sidebar;
