import { useFormik } from "formik";
import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import * as Yup from "yup"
import { axiosDROPPAdmin } from "../../services/api";
import { toast } from "react-toastify";

const initialValues ={
    password:"",
    new_password:"",
    confirm_password:""
}

const validationSchema = Yup.object().shape({
    password: Yup.string().trim().required("Please enter old password"),
    new_password: Yup.string().trim().required("Please enter new password"),
    confirm_password: Yup.string().trim().required("Please enter confirm password")
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
   
 })

const ChangePasswordModal = ({ isOpen, onClose}) => {
    const [loading,setLoading] = useState(false)
    const submit = async (value) =>{
        setLoading(true)
        try{
            btoa(values?.password?.trim())
            const res = await axiosDROPPAdmin.post("changePassword",{
                password: btoa(values?.password?.trim()),
                new_password: btoa(values?.new_password?.trim()),
                confirm_password: btoa(values?.confirm_password?.trim())
            });
            if(!!res){
                setLoading(false)
                toast.success(res?.data?.message)
                onClose()
            }
        }catch(err){
            setLoading(false)
            toast.error(err?.response?.data?.message || err?.message)
        }
    }

    const {handleChange,handleBlur,values,errors,touched,handleSubmit} = useFormik({
        initialValues,validationSchema,onSubmit:submit
    })
    return (
        <Modal className="h-100"  isOpen={isOpen} centered>
            <ModalHeader toggle={onClose}>
                Change Password
            </ModalHeader>
                <form onSubmit={handleSubmit}>
            <ModalBody>

                <div className="mb-2">

                {/* <label  class="form-label">Old Password</label> */}
               <input class="form-control" values={values?.password} type="password" name="password" onChange={handleChange} onBlur={handleBlur} placeholder="Old Password"/>
                {
                    errors?.password && touched?.password &&<span className="text-danger">{errors?.password}</span>
                }
                </div>
                <div className="mb-2">

                {/* <label  class="form-label">New Password
                </label> */}
               <input class="form-control" values={values?.new_password} onChange={handleChange} onBlur={handleBlur} type="password" placeholder="New Password" name="new_password"/>
               {
                    errors?.new_password && touched?.new_password &&<span className="text-danger">{errors?.new_password}</span>
                }
                </div>
                <div>

               {/* <label class="form-label">Confirm Password
                </label> */}
               <input  class="form-control" values={values?.confirm_password} onChange={handleChange} onBlur={handleBlur} type="password" name="confirm_password" placeholder="Confirm Password"/>
               {
                    errors?.confirm_password && touched?.confirm_password &&<span className="text-danger">{errors?.confirm_password}</span>
                }
               </div>

            </ModalBody>
            <ModalFooter>
                <Button disabled={loading} color="danger" type="submit">Submit</Button>
                <Button color="primary" outline onClick={onClose}>Cancel</Button>
            </ModalFooter>
                </form>
        </Modal>
    );
};

export default ChangePasswordModal;
