import { createSlice } from "@reduxjs/toolkit";
import { retailerList } from "./retailerThunk";

const initialState = () => ({
  retailerLoading: false,
  retailerData: [],
  retailerCount: 0,
});

const retailerSlice = createSlice({
  initialState: initialState(),
  name: "retailer",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retailerList.pending, (state, { payload }) => {
        state.retailerLoading = true;
      })
      .addCase(retailerList.fulfilled, (state, { payload }) => {
        state.retailerLoading = false;
        state.retailerData = payload?.data?.rows;
        state.retailerCount = payload?.data?.count;
      })
      .addCase(retailerList.rejected, (state, { payload }) => {
        state.retailerLoading = false;
      });
  },
});

export const {} = retailerSlice.actions;
export default retailerSlice.reducer;
