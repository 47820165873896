import { Navigate } from "react-router";
import routesConstants from "./routeConstant";
import {
  CategoryPage,
  CustomersPage,
  HomePage,
  LoginPage,
  OrderPage,
  PrivacyPolicyPage,
  ProductPage,
  ReferralPage,
  RetailerPage,
  SignupPage,
  TermsConditionPage,
} from "./routeImport";

const routesConfig = {
  common: [
    {
      path: routesConstants.TERMSANDCONDITION,
      Component: TermsConditionPage,
    },
    {
      path: routesConstants.PRIVACY,
      Component: PrivacyPolicyPage,
    },
  ],
  private: [
    {
      path: "/",
      Component: () => <Navigate to={routesConstants.CUSTOMERS} />,
    },
    {
      path: routesConstants.CUSTOMERS,
      Component: CustomersPage,
    },
    {
      path: routesConstants.RETAILERS,
      Component: RetailerPage,
    },
    {
      path: routesConstants.PRODUCTS,
      Component: ProductPage,
    },
    {
      path: routesConstants.CATEGORY,
      Component: CategoryPage,
    },
    {
      path: routesConstants.ORDERS,
      Component: OrderPage,
    },
    {
      path: routesConstants.REFERRAL,
      Component: ReferralPage,
    },
    {
      path: "*",
      Component: () => <Navigate to={routesConstants.CUSTOMERS} />,
    },
  ],
  public: [
    {
      path: "/",
      Component: <Navigate to={routesConstants.LOGIN} />,
    },
    {
      path: routesConstants.LOGIN,
      Component: LoginPage,
    },
    {
      path: routesConstants.SIGNUP,
      Component: SignupPage,
    },
    {
      path: "*",
      Component: () => <Navigate to={routesConstants.LOGIN} />,
    },
    {
      path: routesConstants.HOME,
      Component: HomePage,
    },
  ],
};

export default routesConfig;
