const routesConstants = {
  LOGIN: "admin/login",
  SIGNUP: "admin/signup",
  CUSTOMERS: "admin/customers",
  RETAILERS: "admin/retailers",
  PRODUCTS: "admin/products",
  CATEGORY: "admin/category",
  ORDERS: "admin/orders",
  REFERRAL: "admin/referral",
  PRIVACY: "privacy-policy",
  TERMSANDCONDITION: "terms-condition",
  HOME: "/",
};

export default routesConstants;
