import axios from "axios";
import Cookies, { cookieKeys } from "./cookies";
import React from "react";
import LocalStorage from "./localStorage";
import SessionStorage from "./sessionStorage";
import { getBrowserName, getOSName } from "../utils/getDeviceInfo";

// export const DROPP_API_URL = `http://192.168.1.139:7000/api/v1`;
// export const DROPP_API_URL = "http://192.168.1.110:8083/api/";
// export const DROPP_API_URL = "http://192.168.1.101:8083/api/";
// export const DROPP_API_URL = "http://192.168.1.110:8083/api/";
// export const DROPP_API_URL = "http://192.168.1.145:8083/api/";
// export const DROPP_API_URL = `http://192.168.1.92:8083/api/`;
export const DROPP_API_URL = "https://thedropp.co.in/api/";
// export const DROPP_API_URL = "https://ec2-18-191-156-154.us-east-2.compute.amazonaws.com/api/";

class Axios {
  constructor(baseURL) {
    this.axios = axios.create({
      baseURL,
    });

    this.axios.interceptors.request.use(this._requestMiddleware);

    this.axios.interceptors.response.use(
      this._responseMiddleware,
      this._responseErr
    );
  }

  _requestMiddleware = (req) => {
    // Send Auth token on every request
    const token = Cookies.get(cookieKeys.TOKEN);
    if (!!token) {
      req.headers.Authorization = "Bearer " + token;
    }
    // req.headers.type = 1;
    req.headers.version = React.version;
    req.headers.model = getBrowserName();
    req.headers.os = getOSName();
    req.headers.RequestFromOrigin = "react";

    return req;
  };

  _responseMiddleware = (response) => {
    //  Do something on every success full response

    return response;
  };

  _responseErr = (error) => {
    if (error?.response?.status === 401) {
      window.open("/admin", "_self");
      if (
        Cookies.get(cookieKeys.TOKEN) === Cookies.get(cookieKeys.REFRESH_TOKEN)
      ) {
        Cookies.clear();
        LocalStorage.clear();
        SessionStorage.clear();
        window.open("/admin", "_self");
        return Promise.reject(error);
      } else {
        Cookies.set(cookieKeys.TOKEN, Cookies.get(cookieKeys.REFRESH_TOKEN));
        return Promise.reject(error);
      }
      // Logout / Redirect

      //Clear required local information
    }

    return Promise.reject(error);
  };
}

const axiosDROPPAdmin = new Axios(DROPP_API_URL).axios;

export { axiosDROPPAdmin };
