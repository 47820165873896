import { createSlice } from "@reduxjs/toolkit";
import { productList } from "./productThunk";

const initialState = () => ({
  productLoading: false,
  productData: [],
  productCount: 0,
});

const productSlice = createSlice({
  initialState: initialState(),
  name: "retailer",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(productList.pending, (state, { payload }) => {
        state.productLoading = true;
      })
      .addCase(productList.fulfilled, (state, { payload }) => {
        state.productLoading = false;
        state.productData = payload?.data?.rows;
        state.productCount = payload?.data?.count;
      })
      .addCase(productList.rejected, (state, { payload }) => {
        state.productLoading = false;
      });
  },
});

export const {} = productSlice.actions;
export default productSlice.reducer;
