import { createAsyncThunk } from "@reduxjs/toolkit";
import { LIST_ALL_CATEGORY_FOR_SHOP } from "../../../constants/url_helpers";
import { axiosDROPPAdmin } from "../../../services/api";

export const categoryList = createAsyncThunk(
  "category/categoryList",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.post(LIST_ALL_CATEGORY_FOR_SHOP, payload);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);
