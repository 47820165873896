import { createSlice } from "@reduxjs/toolkit";
import { categoryList } from "./categoryThunk";

const initialState = () => ({
  categoryLoading: false,
  categoryData: [],
});

const categorySlice = createSlice({
  initialState: initialState(),
  name: "category",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(categoryList.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(categoryList.fulfilled, (state, { payload }) => {
        state.categoryLoading = false;
        state.categoryData = payload?.data;
      })
      .addCase(categoryList.rejected, (state) => {
        state.categoryLoading = false;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = categorySlice.actions;
export default categorySlice.reducer;
