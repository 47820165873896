import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_REFERRAL_LIST } from "../../../constants/url_helpers";
import { axiosDROPPAdmin } from "../../../services/api";

export const referralList = createAsyncThunk(
  "order/orderList",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.post(GET_REFERRAL_LIST, payload);
      let json = await res.data;
      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);
