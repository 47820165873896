import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../module/login/features/authSlice";
import usersSlice from "../module/users/features/usersSlice";
import dashboardSlice from "../module/dashboard/features/dashboardSlice";
import commonSlice from "./commonSlice";
import retailerSlice from "../module/retailer/features/retailerSlice";
import productSlice from "../module/products/features/productSlice";
import categorySlice from "../module/category/features/categorySlice";
import orderSlice from "../module/orders/features/orderSlice";
import referralSlice from "../module/referral/features/referralSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    user: usersSlice,

    dashboard: dashboardSlice,
    common: commonSlice,
    retailer: retailerSlice,

    product: productSlice,
    category: categorySlice,
    order: orderSlice,
    referral: referralSlice,
  },
  devTools: true,
});

export default store;
