import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosDROPPAdmin } from "../../../services/api";
import { LIST_RETAILER } from "../../../constants/url_helpers";

export const retailerList = createAsyncThunk(
  "retailer/retailerList",
  async (payload, thunk) => {
    try {
      const res = await axiosDROPPAdmin.post(LIST_RETAILER, payload);
      let json = await res.data;

      return json;
    } catch (e) {
      return thunk.rejectWithValue(e?.response?.data);
    }
  }
);

