import "./assets/css/index.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./routes";
import { ToastContainer } from "react-toastify";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

const App = () => {
  return (
    <div className="App">
      <Routes />
      <ToastContainer toastClassName={"toastify"} autoClose={5000} />
    </div>
  );
};

export default App;
